import * as React from 'react';

import MotionPlayer from 'components/motion/MotionPlayer';

import NotFoundLottie from 'assets/lottie/404.json';

import * as Styled from '../style/404.styles';

const NotFoundPage = () => {
	return (
		<Styled.Section>
			<MotionPlayer
				src={NotFoundLottie}
				isVisible={true}
				position="unset"
				width={{
					xs: '100%',
					sm: '100%',
					md: '50%',
					lg: '50%',
					xl: '50%',
					'2xl': '50%',
				}}
				mx="auto"
			/>
		</Styled.Section>
	);
};

export default NotFoundPage;
